/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Accordion, Button, BlockContent } from "../../../../components"
import { scrollToElement } from "../../../../utils"
import styles from "./style.module.scss"

const Content = ({ id, title, defaultOpen, content, backgroundColor, border }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])

  const onCloseClick = () => {
    scrollToElement(window, isOpen, id)
    setIsOpen(false)
  }

  const contentStyle = classNames(styles.content, {
    [styles.hidden]: !isOpen,
    [styles.yellowBackground]: backgroundColor === "yellow",
    [styles.whiteBackground]: backgroundColor === "white",
    [styles.yellowBorder]: border === "yellow",
  })

  return (
    <div className={styles.item}>
      <Accordion.Header
        id={id}
        title={title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        backgroundColor="white"
        yellowBackgroundBorder={border === "yellow"}
      />
      <div className={classNames("markdown", contentStyle)}>
        <BlockContent blocks={content || []} />
        <div className={styles.closeContent}>
          <Button.Tertiary
            type="button"
            icon="cross"
            className={styles.closeButton}
            onClick={onCloseClick}
          >
            Lukk
          </Button.Tertiary>
        </div>
      </div>
    </div>
  )
}

Content.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.any).isRequired,
  backgroundColor: PropTypes.oneOf(["yellow", "white"]),
  border: PropTypes.oneOf(["", "yellow"]),
  defaultOpen: PropTypes.bool,
}

Content.defaultProps = {
  backgroundColor: "yellow",
  border: "",
  defaultOpen: false,
}

export default Content
