import React, { useState, useEffect } from "react"
import { BackLink, IllustrationLink, Title, Loading } from "../../../components"
import SEO from "../../../components/SEO"
import Resource from "./_resource"
import OrganizationLinks from "./_organizationLinks"
import styles from "./style.module.scss"
import { IndustryType } from "../../../propTypes"
import { withDefaults } from "../../../utils"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { H, Section } from "../../../state/levelContext"

const BistandOgRessurserPage = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "supportResourcesPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->route in [$industryRoute, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          route
        },
        illustrationLinkLarge {
          ...,
          image {
            ...,
            asset-> {
              url,
            },
          },
          ingress[] ${blockContentGroq},
        },

        moreResources[] {
          ...,
          ingress[] ${blockContentGroq},
          supportList[] ${blockContentGroq},
        },

        organizationsImage {
          ...,
          asset-> {
            url,
          },
        },

        organizationsIngress[] ${blockContentGroq},

        resources[] {
          ...,
          ingress[] ${blockContentGroq},
          supportList[] ${blockContentGroq},
        },
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.route)

  return (
    <>
      <SEO title="Bistand og ressurser" />
      <Section>
        <Title.Default title={page.title} />

        <IllustrationLink.Large
          title={page.illustrationLinkLarge.title}
          content={page.illustrationLinkLarge.ingress}
          linkText={page.illustrationLinkLarge.buttonText}
          url={`/bransje/${industry.route}/vanlige-sporsmal`}
          imageSource={page.illustrationLinkLarge.image.asset.url}
          imageAltText={page.illustrationLinkLarge.image.alt}
          className={styles.topIllustration}
          portableContent
        />

        <H className="center-text h2">{page.title2}</H>
        {page.resources.map(resource => {
          return (
            <Resource
              key={`resource-${resource.title}`}
              title={resource.title}
              ingress={resource.ingress}
              buttonURL={resource.buttonURL}
              buttonText={resource.buttonText}
              subTitle={resource.subTitle}
              supportText={resource.supportList}
              className="primary-background"
            />
          )
        })}

        <h2 className={styles.resourceTitle}>{page.title3}</h2>

        {page.moreResources.map(resource => {
          return (
            <Resource
              key={`resource-${resource.title}`}
              title={resource.title}
              ingress={resource.ingress}
              buttonURL={resource.buttonURL}
              buttonText={resource.buttonText}
              subTitle={resource.subTitle}
              supportText={resource.supportList}
              className="background-white"
            />
          )
        })}

        <OrganizationLinks
          title={page.organizationsTitle}
          ingress={page.organizationsIngress}
          imageSource={page.organizationsImage.asset.url}
          imageAlt={page.organizationsImage.alt}
          organizations={page.organizationList}
        />

        <BackLink to={`/bransje/${industry.route}`} />
      </Section>
    </>
  )
}

BistandOgRessurserPage.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(BistandOgRessurserPage)
