import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { Link, BackLink, IllustrationLink, Title, Loading } from "../../../components"
import SEO from "../../../components/SEO"
import Advice from "../../../components/advice"
import styles from "./style.module.scss"
import { IndustryType } from "../../../propTypes"
import { withDefaults } from "../../../utils"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { H, Section } from "../../../state/levelContext"

const TipsOgRadPage = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "tipsAndAdvicesPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->route in [$industryRoute, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          route,
        },
        topContent[] ${blockContentGroq},
        tipsAndAdvicesItems[] {
          ...,
          description[] ${blockContentGroq},
        },
        advicesToolsIllustration {
          ...,
          image {
            ...,
            asset-> {
              url
            }
          }
        }
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.route)

  return (
    <>
      <SEO title="Tips og råd" />
      <Section>
        <Title.Default title={page.title} description={page.ingress} content={page.topContent} />
        <div className="content">
          <Section>
            <ol className={classNames(styles.numberedList, styles.list)}>
              {page.tipsAndAdvicesItems.map(advice => {
                return (
                  <Advice key={advice.title} title={advice.title} content={advice.description} />
                )
              })}
            </ol>
          </Section>
          <Section>
            <div className="desktop-col-12 center-content-vertically">
              <H className="h2" style={{ textAlign: "center" }}>
                {page.idebankLinkTitle}
              </H>
              <Link.Blank url={page.idebankURL} external>
                {page.idebankLinkText}
              </Link.Blank>
            </div>
          </Section>
        </div>

        <Section>
          <IllustrationLink.Large
            title={page.advicesToolsIllustration.title}
            content={page.advicesToolsIllustration.ingress}
            portableContent
            linkText={page.advicesToolsIllustration.buttonText}
            url={`/bransje/${industry.route}/verktoy`}
            imageSource={page.advicesToolsIllustration.image.asset.url}
            imageAltText={page.advicesToolsIllustration.image.alt}
            illustrationOverflowY
            background="yellow"
            isTool
          />
        </Section>

        <BackLink to={`/bransje/${industry.route}`} />
      </Section>
    </>
  )
}

TipsOgRadPage.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(TipsOgRadPage)
