import Portal from "./portal"

import Personvern from "./personvern"
import Bedriftshelsetjenesten from "./bedriftshelsetjenesten"
import Om from "./om"
import Kunnskapsmodulen from "./kunnskapsmodulen"
import Kontakt from "./kontakt"
import NotFound from "../pages/404"
import Index from "./main"

export {
  Portal,
  Personvern,
  Bedriftshelsetjenesten,
  Om,
  Kunnskapsmodulen,
  Kontakt,
  NotFound,
  Index,
}

const prefixFunc = (prefix, object) =>
  Object.entries(object).reduce((accumulator, currentValue) => {
    const [key, value] = currentValue
    accumulator[`${prefix}${key}`] = value
    return accumulator
  }, {})

export const ComponentList = {
  ...prefixFunc("Portal.", Portal),
  Personvern,
  Bedriftshelsetjenesten,
  Om,
  Kunnskapsmodulen,
  Kontakt,
  NotFound,
  Index,
}
