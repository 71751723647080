import React, { useState } from "react"
import PropTypes from "prop-types"
import { BackLink, Title, Loading } from "../components"
import SEO from "../components/SEO"
import ContactOption from "./_kontakt/_kontaktOption"
import KontaktIndustrySelector from "./_kontakt/_kontaktIndustrySelector"
import KontaktHorisontal from "./_kontakt/_kontaktHorisontal"
import styles from "./_kontakt/style.module.scss"
import { IndustryType } from "../propTypes"
import withPageDependencies from "../hoc/withPageDependencies"
import useSanity from "../hooks/useSanity"
import { blockContentGroq } from "../groq"

const ContactPage = ({ industries, industry }) => {
  const [params] = useState({ id: "contactPage" })

  const { loading, error, data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
        ...,
        resourcesIngress[] ${blockContentGroq},
        portalIngress[] ${blockContentGroq},
        industrySelectorIngress[] ${blockContentGroq},
      } | order(_updatedAt desc) [0] `,
    params
  )
  const page = data

  if (loading || error) return <Loading error={error} />

  return (
    <>
      <SEO title={page.title} />
      <Title.Default title={page.title} description={page.ingress} />
      <div className="content" style={{ padding: "0rem", justifyContent: "space-between" }}>
        {industry ? (
          <ContactOption
            title={page.resourcesTitle}
            content={page.resourcesIngress}
            buttonURL={`/bransje/${industry.route}/bistand-og-ressurser`}
            buttonText={page.resourcesButtonText}
            className={styles.contactItem}
            icon="arrow"
          />
        ) : (
          <KontaktIndustrySelector
            title={page.industrySelectorTitle}
            content={page.industrySelectorIngress}
            industries={industries}
            className={styles.contactItem}
          />
        )}

        <ContactOption
          title={page.portalTitle}
          content={page.portalIngress}
          buttonURL={page.portalButtonURL}
          buttonText={page.portalButtonText}
          external
          icon="mail"
        />
      </div>
      <KontaktHorisontal
        title={page.reportingTitle}
        buttonURL={page.reportingButtonURL}
        buttonText={page.reportingButtonText}
        icon="mail"
      />

      <BackLink to={industry ? `/bransje/${industry.route}` : "/"} />
    </>
  )
}

ContactPage.propTypes = {
  industries: PropTypes.arrayOf(IndustryType).isRequired,
  industry: IndustryType,
}

ContactPage.defaultProps = {
  industry: null,
}

export default withPageDependencies(ContactPage)
