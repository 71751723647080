import React, { useState } from "react"
import { BackLink, Title, Chapter, Loading } from "../components"
import { PartsLogo } from "./_about/_index"
import SEO from "../components/SEO"
import { IndustryType } from "../propTypes"
import withPageDependencies from "../hoc/withPageDependencies"
import useSanity from "../hooks/useSanity"
import { blockContentGroq } from "../groq"

const Om = ({ industry }) => {
  const [params] = useState({ id: "aboutPage" })
  const { loading, error, data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
        ...,
        chapterItems[] {
          ...,
          content[]  ${blockContentGroq}
        },
        partners[] {
          ...,
          image {
            ...,
            asset->
          }
        }
      } | order(_updatedAt desc) [0]`,
    params
  )

  const page = data

  if (loading || error) return <Loading error={error} />

  return (
    <>
      <SEO title={page.title} description={page.metaDescription} />
      <Title.Default title={page.title} description={page.ingress} />
      <PartsLogo partners={page.partners} />
      {page.chapterItems.map(chapter => (
        <Chapter key={chapter._key} title={chapter.title} content={chapter.content} />
      ))}
      <BackLink to={industry ? `/bransje/${industry.route}` : "/"} />
    </>
  )
}

Om.propTypes = {
  industry: IndustryType,
}

Om.defaultProps = {
  industry: null,
}

export default withPageDependencies(Om)
