import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { IllustrationLink, BackLink, BlockContent, Loading } from "../../../components"
import SEO from "../../../components/SEO"
import StatisticsHero from "./_statisticsHero"
import StatisticsHeroYellowbox from "./_statisticsHeroYellowbox"
import BottomFacts from "./_bottomFacts"
import Fact from "./_fact"
import { IndustryType } from "../../../propTypes"
import layoutStyles from "./layout.module.scss"
import styles from "./style.module.scss"
import { withDefaults } from "../../../utils"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { H, Section } from "../../../state/levelContext"

const StatistikkPage = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "factsPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->route in [$industryRoute, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          route
        },
        ingress[] ${blockContentGroq},
        bransjeingress[] ${blockContentGroq},
        bottomText[] ${blockContentGroq},
        "headerPtil": coalesce(headerPtil[] ${blockContentGroq}, []),
        preventionIngress[] ${blockContentGroq},
        "bottomFactsBlock": coalesce(bottomFactsBlock[] ${blockContentGroq}, []),
        bottomFactsImage {
          ...,
          asset-> {
            url,
          },
        },
        "headerDaysWorkSource": coalesce(headerDaysWorkSource[] ${blockContentGroq}, []),
        bottomFooterIllustration  {
          ...,
          image {
            ...,
            asset-> {
              url,
            },
          },
          ingress[] ${blockContentGroq},
        },
        facts[] {
          ...,
          infoBox {
            ...,
            content[] ${blockContentGroq},
            icon {
              ...,
              asset-> {
                url,
              }
            },
          },
        },
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.route)

  return (
    <>
      <SEO title={page.title} />
      <Section>
        <div className={layoutStyles.container}>
          <div className={layoutStyles.content}>
            <H className="center-text h1">{page.title}</H>
            <Section>
              <div className={layoutStyles.ingress}>
                <BlockContent blocks={page.ingress || []} />
              </div>
            </Section>
            {page.bransjeingress && (
              <Section>
                <div className={classNames(styles.headerContainer, "center-text")}>
                  <BlockContent blocks={page.bransjeingress || []} />
                </div>
              </Section>
            )}
            <Section>
              <H className="center-text h2">{page.secondTitle}</H>
              <p className="center-text">{page.secondIngress}</p>
              {page.facts.map(fact => {
                return <Fact key={fact._key} fact={fact} />
              })}
            </Section>
            <Section>
              <StatisticsHero statisticsTexts={page} />
            </Section>
            <Section>
              <StatisticsHeroYellowbox statisticsTexts={page} />
            </Section>
            <Section>
              <BottomFacts
                blockContent={page.bottomFactsBlock || []}
                imageSource={page.bottomFactsImage.asset.url}
                imageAltText={page.bottomFactsImage.alt}
              />
            </Section>
            <Section>
              <div className={layoutStyles.ingress}>
                <BlockContent blocks={page.bottomText || []} />
              </div>
            </Section>
          </div>
        </div>
        {!industry.showFactsPageOnly ? (
          <Section>
            <IllustrationLink.Large
              title={page.bottomFooterIllustration.title}
              content={page.bottomFooterIllustration.ingress}
              portableContent
              linkText={page.bottomFooterIllustration.buttonText}
              url={`/bransje/${industry.route}/verktoy`}
              imageSource={page.bottomFooterIllustration.image.asset.url}
              imageAltText={page.bottomFooterIllustration.image.alt}
              background="yellow"
              style={{ marginTop: "6rem" }}
              illustrationOverflowY
              isTool
            />
            <BackLink to={`/bransje/${industry.route}`} />
          </Section>
        ) : (
          <div style={{ paddingBottom: "8rem" }} />
        )}
      </Section>
    </>
  )
}

StatistikkPage.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(StatistikkPage)
