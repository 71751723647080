import React from "react"
import { Title, SEO } from "../components"
import withPageDependencies from "../hoc/withPageDependencies"

const NotFoundPage = () => (
  <>
    <SEO title="404: Siden ble ikke funnet" />
    <Title.Default
      title="Siden ble ikke funnet"
      description="Adressen du forsøkte å nå finnes ikke."
    />
  </>
)

export default withPageDependencies(NotFoundPage)
