import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { BlockContent, Button } from "../../../components"
import { H } from "../../../state/levelContext"

const RolesSelector = ({
  rolesAndPoints,
  rolesAndPointsPtil,
  cooperationTitle,
  cooperationText,
  responsiblesTitle,
  responsiblesContent,
  actorsTitle,
}) => {
  const ptilRoles = [...rolesAndPoints, ...rolesAndPointsPtil]
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { text } = ptilRoles[selectedIndex]
  const { title } = ptilRoles[selectedIndex]

  const tabItemStyle = index => {
    return classNames(styles.roleButton, {
      [styles.roleSelected]: index === selectedIndex,
    })
  }

  return (
    <>
      <div className={classNames("content", styles.rolesContainer)}>
        <div className="content" style={{ paddingBottom: "0rem" }}>
          {responsiblesTitle && (
            <div className="desktop-col-9 center-content">
              <H className="h2">{responsiblesTitle}</H>
            </div>
          )}
          {responsiblesContent && (
            <div className="desktop-col-8 center-content center-text">
              <BlockContent blocks={responsiblesContent} />
            </div>
          )}
          {actorsTitle && (
            <div className="desktop-col-12 center-content center-text">
              <H className="h3">{actorsTitle}</H>
            </div>
          )}
        </div>
        <div style={{ paddingTop: "0rem" }}>
          <div className={classNames("desktop-col-12", styles.buttonsContainer)}>
            {ptilRoles.map((role, index) => (
              <Button.Secondary
                type="button"
                key={role.title}
                onClick={() => setSelectedIndex(index)}
                className={tabItemStyle(index)}
              >
                {role.title}
              </Button.Secondary>
            ))}
          </div>
        </div>
      </div>

      <div className="content">
        <div className="desktop-col-5">
          <H className="h2">{title}</H>
        </div>
        <div className="desktop-col-7">
          <BlockContent blocks={text} />
        </div>
      </div>
      <div style={{ backgroundColor: "#FAF5E9" }}>
        <div className="content">
          <div className="desktop-col-5">
            <H className="h2">{cooperationTitle}</H>
          </div>
          <div className="desktop-col-7">
            <BlockContent blocks={cooperationText} />
          </div>
        </div>
      </div>
    </>
  )
}

RolesSelector.propTypes = {
  rolesAndPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  rolesAndPointsPtil: PropTypes.arrayOf(PropTypes.object).isRequired,
  cooperationTitle: PropTypes.string.isRequired,
  cooperationText: PropTypes.arrayOf(PropTypes.object).isRequired,
  responsiblesTitle: PropTypes.string.isRequired,
  responsiblesContent: PropTypes.arrayOf(PropTypes.object).isRequired,
  actorsTitle: PropTypes.string.isRequired,
}

export default RolesSelector
