import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { DropDown, BlockContent } from "../../components"
import { IndustryType } from "../../propTypes"

const ContactIndustrySelector = ({ title, content, style, industries, className }) => {
  const allIndustryOptions = industries.map(v => {
    return { value: v.route, label: v.title }
  })

  const contactsStyling = classNames("desktop-flexcol", styles.contactContainer, className)
  return (
    <div className={contactsStyling} style={style}>
      <div>
        <h3 className="title" style={{ marginLeft: "5rem", marginRight: "5rem" }}>
          {title}
        </h3>
      </div>
      <div style={{ marginTop: "auto" }}>
        <div style={{ marginLeft: "5rem", marginRight: "5rem" }}>
          <BlockContent blocks={content} />
        </div>
        <div style={{ maxWidth: "50%", margin: "5rem" }}>
          <DropDown
            name="industry"
            id="industry"
            options={allIndustryOptions}
            placeholder="Velg bransje"
            onChange={event => navigate(`/bransje/${event.value}/bistand-og-ressurser`)}
            ariaLabel="Velg en annen bransje"
          />
        </div>
      </div>
    </div>
  )
}

ContactIndustrySelector.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

ContactIndustrySelector.defaultProps = {
  style: {},
  className: null,
}

export default ContactIndustrySelector
