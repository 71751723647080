import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { Button } from "../../components"

const ContactHorisontal = ({ title, buttonURL, buttonText, style, icon }) => {
  const contactsStyling = classNames(styles.horisontalContainer, "content")

  return (
    <div className={contactsStyling} style={style}>
      <div className="desktop-col-8">
        <h3 className="title" style={{ marginLeft: "5rem" }}>
          {title}
        </h3>
      </div>
      <div className="desktop-col-4 center-content">
        <Button.Secondary url={buttonURL} style={{ margin: "2rem" }} type="external" icon={icon}>
          {buttonText}
        </Button.Secondary>
      </div>
    </div>
  )
}

ContactHorisontal.propTypes = {
  title: PropTypes.string.isRequired,
  buttonURL: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  icon: PropTypes.string,
}

ContactHorisontal.defaultProps = {
  style: {},
  icon: null,
}

export default ContactHorisontal
