import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link, BlockContent } from "../../../components"
import styles from "./style.module.scss"
import { H } from "../../../state/levelContext"

const OrganizationLinks = ({ title, ingress, imageSource, organizations, imageAlt }) => {
  return (
    <div className={classNames("content", "primary-background", styles.organizationLinks)}>
      <div className="col desktop-col-8">
        <H className="h1">{title}</H>
        <BlockContent blocks={ingress} />
        <p style={{ marginTop: "6rem" }}>
          {organizations.map(({ text, href }) => {
            return (
              <Link.Blank key={href} url={href} style={{ marginRight: "4rem" }} external>
                {text}
              </Link.Blank>
            )
          })}
        </p>
      </div>
      <div className="col desktop-col-4 center-content mobile-display-none">
        <img style={{ maxWidth: "253px" }} src={imageSource} alt={imageAlt} />
      </div>
    </div>
  )
}

OrganizationLinks.propTypes = {
  title: PropTypes.string.isRequired,
  ingress: PropTypes.arrayOf(PropTypes.object).isRequired,
  imageSource: PropTypes.string.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  imageAlt: PropTypes.string.isRequired,
}

export default OrganizationLinks
