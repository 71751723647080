import React from "react"
import { Router } from "@reach/router"
import { ComponentList } from "../templates"
import TemplateRouter from "../templates/routerWrapper"
import { SITE_ROUTES } from "../routing"

const renderListRecursive = children => {
  return children.flatMap(r => {
    const Component = Object.entries(ComponentList).find(a => a[0] === r.component)[1]
    const result = [<Component key={r.path} path={r.path} />]
    if (r.children)
      result.push(
        <TemplateRouter key={`${r.path}/index`} path={r.path}>
          {renderListRecursive(r.children)}
        </TemplateRouter>
      )
    return result
  })
}

const IndexRouter = () => {
  return <Router>{renderListRecursive(SITE_ROUTES)}</Router>
}

export default IndexRouter
