import Main from "./main"
import Verktoy from "./verktoy"
import TipsOgRad from "./tips-og-rad"
import Styringssloyfen from "./styringssloyfen"
import RollerOgSamarbeid from "./roller-og-samarbeid"
import FaktaOmBransjen from "./fakta-om-bransjen"
import BistandOgRessurser from "./bistand-og-ressurser"
import VanligeSporsmal from "./vanlige-sporsmal"

export default {
  Main,
  Verktoy,
  TipsOgRad,
  Styringssloyfen,
  RollerOgSamarbeid,
  FaktaOmBransjen,
  BistandOgRessurser,
  VanligeSporsmal,
}
