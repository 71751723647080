/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import { BlockContent } from "."
import { H } from "../state/levelContext"

function Advice({ title, content }) {
  return (
    <li style={{ marginBottom: "2rem" }} className="desktop-col-5">
      <H className="h4" style={{ marginLeft: "5rem" }}>
        {title}
      </H>
      <div>
        <BlockContent blocks={content} />
      </div>
    </li>
  )
}

Advice.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Advice
