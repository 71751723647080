import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import layoutStyles from "./layout.module.scss"
import { BlockContent } from "../../../components"
import { H, Section } from "../../../state/levelContext"

const StatisticsHeroYellowbox = ({ statisticsTexts }) => {
  const isPetroleum = statisticsTexts.industry.route === "petroleum"
  const flexStyling = classNames("center-text", layoutStyles.flex)

  return (
    <>
      <div className={styles.headerContainer}>
        <H className="center-text h2">{statisticsTexts.headerTitle}</H>
        {isPetroleum && (
          <Section>
            <div className="center-content">
              <BlockContent blocks={statisticsTexts.headerPtil} />
            </div>
          </Section>
        )}
        {!isPetroleum && (
          <Section>
            <div className={flexStyling}>
              <div className={layoutStyles.flexItem} style={{ padding: 0 }}>
                <p>{statisticsTexts.headerPercentTitle}</p>
                <p className={styles.headerBoldLabel}>{statisticsTexts.headerPercent}</p>
                <p>{statisticsTexts.headerPercentFooter}</p>
              </div>
              <div className={layoutStyles.flexItem} style={{ padding: 0 }}>
                <p>{statisticsTexts.headerDaysWorkTitle}</p>
                <p className={styles.headerBoldLabel}>{statisticsTexts.headerDaysWork}</p>
                <p>{statisticsTexts.headerDaysWorkFooter}</p>
              </div>
            </div>
          </Section>
        )}
        <Section>
          <div style={{ textAlign: "right" }}>
            <BlockContent blocks={statisticsTexts.headerDaysWorkSource} />
          </div>
        </Section>
      </div>
    </>
  )
}

StatisticsHeroYellowbox.propTypes = {
  statisticsTexts: PropTypes.shape({
    headerTitle: PropTypes.string.isRequired,
    headerPtil: PropTypes.arrayOf(PropTypes.object),
    headerPercentTitle: PropTypes.string.isRequired,
    headerPercent: PropTypes.string.isRequired,
    headerPercentFooter: PropTypes.string.isRequired,
    headerDaysWorkTitle: PropTypes.string.isRequired,
    headerDaysWork: PropTypes.string.isRequired,
    headerDaysWorkFooter: PropTypes.string.isRequired,
    headerDaysWorkSource: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types
    industry: PropTypes.object.isRequired,
  }).isRequired,
}

export default StatisticsHeroYellowbox
