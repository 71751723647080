import React, { useState, useEffect } from "react"
import { IllustrationLink, BackLink, Title, Loading } from "../../../components"
import SEO from "../../../components/SEO"
import RolesSelector from "./_rolesSelector"
import { IndustryType } from "../../../propTypes"
import { withDefaults } from "../../../utils"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { Section } from "../../../state/levelContext"

const RollerOgPartssamarbeid = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "rolesPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->route in [$industryRoute, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          route,
          title,
        },
        topImage {
          asset-> {
            url
          },
          alt
        },
        bottomGraphics {
          ...,
          image {
            ...,
            asset-> {
              url
            },
            alt
          }
        },
        roles[] {
          ...,
          text[] ${blockContentGroq}
        },
        cooperationText[] ${blockContentGroq},
        "rolesPtil": coalesce(rolesPtil[] {
          ...,
          text[] ${blockContentGroq}
        }, []),
        "responsiblesPtilTitle": coalesce(responsiblesPtilTitle, ""),
        "responsiblesPtilIngress": coalesce(responsiblesPtilIngress[] ${blockContentGroq}, []),
        "actorsTitlePtil": coalesce(actorsTitlePtil, ""),
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.route)

  return (
    <>
      <SEO title="Roller og samarbeid" />
      <Section>
        <Title.Default title={page.title} description={page.ingress} />

        <div className="content">
          <div className="desktop-col-9 center-content">
            <img
              style={{ maxWidth: "336px" }}
              src={page.topImage.asset.url}
              alt={page.topImage.alt}
            />
          </div>
        </div>

        <Section>
          <RolesSelector
            rolesAndPoints={page.roles}
            cooperationTitle={page.cooperationTitle}
            cooperationText={page.cooperationText}
            rolesAndPointsPtil={page.rolesPtil}
            responsiblesTitle={page.responsiblesPtilTitle}
            responsiblesContent={page.responsiblesPtilIngress}
            actorsTitle={page.actorsTitlePtil}
          />
        </Section>
        <Section>
          <IllustrationLink.Large
            style={{ marginTop: "4rem" }}
            title={page.bottomGraphics.title}
            content={page.bottomGraphics.ingress}
            linkText={page.bottomGraphics.buttonText}
            url={`/bransje/${industry.route}/verktoy`}
            imageSource={page.bottomGraphics.image.asset.url}
            imageAltText={page.bottomGraphics.image.alt}
            background="yellow"
            illustrationOverflowY
            isTool
            portableContent
          />
        </Section>

        <BackLink to={`/bransje/${industry.route}`} />
      </Section>
    </>
  )
}

RollerOgPartssamarbeid.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(RollerOgPartssamarbeid)
