/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Accordion, BlockContent } from "../../components"
import styles from "./style.module.scss"
import { H, Section } from "../../state/levelContext"

const Factors = ({ title, ingress, items }) => {
  return (
    <section className={classNames("primary-background", styles.factors)} aria-label={title}>
      <div className={classNames("content", styles.header)}>
        <div className="desktop-col-8 tablet-col-8">
          <H>{title}</H>
          <Section>
            <BlockContent blocks={ingress} />
          </Section>
        </div>
      </div>

      <div className="content">
        <Accordion.VerticalContent
          items={items}
          backgroundColor="white"
          border="yellow"
          portableContent
        />
      </div>
    </section>
  )
}

Factors.propTypes = {
  title: PropTypes.string.isRequired,
  ingress: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      contentTitle: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ).isRequired,
}

export default Factors
