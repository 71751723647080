/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import { Accordion, Button, BlockContent } from "../../../components"
import AccordionContent from "./_accordionContent"
import styles from "./style.module.scss"
import { H } from "../../../state/levelContext"

// Presents correct loop content based on activeId
const LoopContent = ({ loop, activeId }) => {
  const getActiveLoopContent = () => {
    const item = loop.find(d => d.id === activeId)
    switch (activeId) {
      case 1:
        return (
          <div>
            <H className="h2">{item.title}</H>
            <BlockContent blocks={item.content || []} />
          </div>
        )
      case 2:
        return (
          <div>
            <H className="h2">{item.title}</H>
            <BlockContent style={{ marginBottom: "5rem" }} blocks={item.content1 || []} />
            {item.accordions.map(acc => {
              return (
                <Accordion.CustomContent
                  id={acc._key}
                  key={acc._key}
                  title={acc.headerTitle}
                  headerBackgroundColor="white"
                  style={{ marginBottom: "2rem" }}
                >
                  <AccordionContent
                    icon={acc.icon.asset.url}
                    iconAlt={acc.icon.alt}
                    title={acc.title}
                    content={acc.content}
                  />
                </Accordion.CustomContent>
              )
            })}
            <BlockContent
              style={{ marginTop: "5rem", marginBottom: "3rem" }}
              blocks={item.content2 || []}
            />
            <Button.Secondary type="external" url={item.buttonURL}>
              {item.buttonText}
            </Button.Secondary>
          </div>
        )
      case 3:
        return (
          <div>
            <H className="h2">{item.title}</H>
            <BlockContent blocks={item.content || []} />
          </div>
        )
      case 4:
        return (
          <div>
            <H className="h2">{item.title}</H>
            <BlockContent blocks={item.content1 || []} />
            <div className={styles.tiltakshierarkiet}>
              <h3>{item.illustration.text}</h3>
              <img
                src={item.illustration.image}
                alt={item.illustration.imageAlt}
                title={item.illustration.imageTooltip}
              />
            </div>
            <BlockContent blocks={item.content2 || []} />
          </div>
        )
      case 5:
        return (
          <div>
            <H className="h2">{item.title}</H>
            <BlockContent blocks={item.content || []} />
          </div>
        )
      default:
        return null
    }
  }

  return getActiveLoopContent()
}

LoopContent.propTypes = {
  loop: PropTypes.arrayOf(PropTypes.object),
  activeId: PropTypes.number,
}

LoopContent.defaultProps = {
  loop: [],
  activeId: null,
}

export default LoopContent
