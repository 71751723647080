import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { Link, Button } from "../../../components"
import style from "./style.module.scss"
import { IndustryType } from "../../../propTypes"
import AutosuggestTextbox from "./autosuggestTextbox"
import crossIcon from "../../../images/icons/cross.svg"
import { H } from "../../../state/levelContext"
import { eventLogger } from "../../../utils"

function FrontpageIndustrySelector({ title, industries, suggestions }) {
  const location = useLocation()

  const [filteredIndustries, setFilteredIndustries] = useState(industries)
  const [currentFilterString, setCurrentFilterString] = useState("")
  const [showFilterResults, setShowFilterResults] = useState(false)
  const [noResultsString, setNoResultsString] = useState("")
  const [moreResultsString, setMoreResultsString] = useState("")

  function setFilter(newFilter) {
    function shouldIncludeIndustry(industry, filterString) {
      let shouldInclude = false
      const cleanedFilterString = filterString.toLowerCase().trim()
      const industryTitle = industry.title.toLowerCase().trim()

      if (industryTitle.indexOf(cleanedFilterString) > -1) {
        shouldInclude = true
      }

      if (industry.jobs) {
        industry.jobs.forEach(job => {
          const jobTitle = job.value.toLowerCase().trim()
          if (jobTitle.indexOf(cleanedFilterString) > -1 && industry.isActive === true) {
            shouldInclude = true
          }
        })
      }

      return shouldInclude
    }

    const filterResults = industries.filter(i => shouldIncludeIndustry(i, newFilter))
    setFilteredIndustries(filterResults)
    setShowFilterResults(newFilter.length > 0)

    if (filterResults.length < 1) {
      setNoResultsString(newFilter)
      eventLogger.logSearchEvent(location, "at.search.negative", newFilter)
    } else {
      setNoResultsString("")
      eventLogger.logSearchEvent(location, "at.search.positive", newFilter)
    }

    if (filterResults.length > 1) {
      setMoreResultsString(newFilter)
    }
  }

  const onFilterChanged = newFilter => {
    setCurrentFilterString(newFilter)
  }

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      setFilter(currentFilterString)
    }
  }

  const handleClick = () => {
    // Siteimprove button event
    window._sz?.push(["event", "Button", "Click", "Search"])
    setFilter(currentFilterString)
  }

  const clickedSuggestion = (s, event) => {
    if (event && event.key !== "Enter") {
      return
    }
    setCurrentFilterString(s)
    setFilter(s)
  }

  const clickedReset = event => {
    if (event && event.key !== "Enter") {
      return
    }
    setCurrentFilterString("")
    setFilter("")
  }

  const goToAllIndustries = () => {
    const comp = window.document.querySelectorAll(".all-industries")
    comp[0].scrollIntoView({ behavior: "smooth" })
  }

  const upcomingIndustries = industries.filter(
    i => i.isActive === true && i.route === "andre-bransjer"
  )

  return (
    <nav aria-label={title} className={style.findIndustryTools}>
      <H className={classNames(style.title, "h3")} style={{ width: "100%", marginBottom: "50px" }}>
        {title}
      </H>
      <div className={style.searchContainer}>
        <div style={{ float: "left", width: "100%" }}>
          <AutosuggestTextbox
            placeholder="Søk på yrke eller bransje"
            onChange={newValue => onFilterChanged(newValue)}
            onKeyDown={e => handleKeyDown(e)}
            industries={industries}
            className={style.inputTextField}
            value={currentFilterString}
          />
        </div>
        {currentFilterString.length > 0 && (
          <div
            role="button"
            onClick={() => clickedReset()}
            onKeyDown={event => clickedReset(event)}
            tabIndex="0"
            className={style.resetSearch}
          >
            <div className={style.resetSearchDiv}>
              <div style={{ marginRight: "5px" }}>Tøm</div>
              <img src={crossIcon} width="18px" alt="Cross" />
            </div>
          </div>
        )}
        {currentFilterString.length === 0 && <div className={style.resetSearchPlaceholder} />}
        <button type="button" className={style.searchButton} onClick={handleClick}>
          Søk
        </button>
      </div>

      {suggestions && suggestions.length > 0 && (
        <div className={style.suggestionsContainer}>
          <div className={style.suggestionsLabel}>Foreslåtte søk:</div>
          {suggestions.map(s => (
            <div
              key={s}
              role="button"
              tabIndex="0"
              onClick={() => clickedSuggestion(s)}
              onKeyDown={event => clickedSuggestion(s, event)}
              className={style.suggestion}
            >
              {s}
            </div>
          ))}
        </div>
      )}

      {showFilterResults && filteredIndustries.filter(i => i.isActive === true).length > 1 && (
        <div className={style.moreResults}>
          <p>
            <span style={{ fontWeight: "bold" }}>{capitalizeFirstLetter(moreResultsString)}</span>{" "}
            ga flere resultater.
          </p>
          <p style={{ fontWeight: "bold" }}>
            Gå til bransjesiden som passer best til din arbeidsplass:
          </p>
        </div>
      )}
      {showFilterResults && (
        <div>
          {filteredIndustries
            .filter(i => i.isActive === true)
            .filter(i => !i.showFactsPageOnly)
            .sort((firstIndustry, secondIndustry) => firstIndustry.sort - secondIndustry.sort)
            .map(industry => (
              <Link.Big
                key={industry.route}
                url={`/bransje/${industry.route}`}
                displayBlock
                style={{ marginBottom: "1rem" }}
              >
                {industry.title}
              </Link.Big>
            ))}
        </div>
      )}
      {noResultsString && (
        <div className={style.noResults}>
          <span style={{ fontWeight: "bold" }}>{capitalizeFirstLetter(noResultsString)}</span> ga
          ingen treff.
        </div>
      )}
      {noResultsString && upcomingIndustries.length > 0 && (
        <div>
          <div className={style.noResultsLesMer}>
            Les mer om arbeidsmiljø og se oversikt over kommende bransjesider:
          </div>
          {upcomingIndustries.map(industry => (
            <Link.Big
              key={industry.route}
              url={`/bransje/${industry.route}`}
              displayBlock
              style={{ marginBottom: "1rem" }}
            >
              {industry.title}
            </Link.Big>
          ))}
        </div>
      )}

      <div style={{ marginTop: "2rem" }}>
        <Button.Secondary icon="arrowDown" onClick={goToAllIndustries} type="button">
          Se alle bransjer
        </Button.Secondary>
      </div>
    </nav>
  )
}

FrontpageIndustrySelector.propTypes = {
  title: PropTypes.string.isRequired,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default FrontpageIndustrySelector
