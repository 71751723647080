import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ArrowDownImage from "../../../images/icons/arrow-down.svg"
import { Button, BlockContent } from "../../../components"
import styles from "./style.module.scss"

function Resource({ title, ingress, buttonURL, buttonText, subTitle, supportText, className }) {
  return (
    <div className={classNames("content", className, styles.contactItem)}>
      <div className="desktop-col-6">
        <div>
          <h3 className={styles.contactTitle}>{title}</h3>
          <BlockContent blocks={ingress} />
        </div>
        <div
          className={classNames(
            "mobile-display-none",
            "tablet-display-none",
            styles.resourceButton,
            {
              [styles.spaceTop]: ingress.length === 0,
            }
          )}
        >
          {buttonURL && (
            <Button.Secondary url={buttonURL} type="external">
              {buttonText}
            </Button.Secondary>
          )}
        </div>
      </div>
      <div className="desktop-col-4">
        <div className={classNames(styles.helpItemsTitle, "label")}>
          {subTitle}
          <img src={ArrowDownImage} alt="" />
        </div>
        <div style={{ marginTop: "3rem" }}>
          <BlockContent blocks={supportText} />
        </div>
        <div className="desktop-display-none" style={{ marginTop: "4rem" }}>
          {buttonURL && (
            <Button.Secondary url={buttonURL} type="external">
              {buttonText}
            </Button.Secondary>
          )}
        </div>
      </div>
    </div>
  )
}

Resource.propTypes = {
  title: PropTypes.string.isRequired,
  ingress: PropTypes.arrayOf(PropTypes.object),
  buttonURL: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  supportText: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
}

Resource.defaultProps = {
  className: null,
  ingress: [],
}

export default Resource
