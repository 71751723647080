import React from "react"
import PropTypes from "prop-types"
import { CircularProgressbar, InfoBox, BlockContent } from "../../../components"
import ArrowIcon from "../../../images/icons/long-arrow.svg"
import styles from "./style.module.scss"
import { H, Section } from "../../../state/levelContext"

const FactEntry = ({ fact }) => {
  return (
    <div className={styles.factContainer}>
      <CircularProgressbar labelledby={fact._key} value={fact.percent} />
      <H className="center-text h2" id={fact._key} aria-label={`${fact.percent} ${fact.title}`}>
        {fact.title}
      </H>
      <Section>
        <div className={styles.factsContainer}>
          <div className={styles.facts}>
            <H className="h3">Knyttet til</H>
            <ul className={styles.list}>
              {fact.relatedToList
                ? fact.relatedToList.map(point => {
                    return <li key={point}>{point}</li>
                  })
                : null}
            </ul>
          </div>
          <div className={styles.factArrow}>
            <img className={styles.factArrowImage} src={ArrowIcon} alt="" />
          </div>
          <div className={styles.facts}>
            <H className="h3">Kan føre til...</H>
            <p>{fact.canLeadTo}</p>
          </div>
        </div>
      </Section>
      {fact.infoBox && (
        <Section>
          <InfoBox
            title={fact.infoBox.title}
            icon={fact.infoBox.icon.asset.url}
            iconAltText={fact.infoBox.icon.alt}
          >
            <BlockContent blocks={fact.infoBox.content} />
          </InfoBox>
        </Section>
      )}
    </div>
  )
}

FactEntry.propTypes = {
  fact: PropTypes.shape({
    _key: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    relatedToList: PropTypes.arrayOf(PropTypes.string).isRequired,
    canLeadTo: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    infoBox: PropTypes.object,
  }).isRequired,
}

export default FactEntry
