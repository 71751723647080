import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import style from "./style.module.scss"
import { IndustryType } from "../../../propTypes"
import { Link } from "../../../components"
import { H } from "../../../state/levelContext"

function ToolDevGeneral({ bulletsTitle, bullets, selectorTitle, industries }) {
  return (
    <div className="content" style={{ marginTop: "5rem", marginBottom: "5rem" }}>
      <div className={classNames("desktop-col-6", style.bullets)}>
        <H className="h2">{bulletsTitle}</H>
        <ul className={style.list}>
          {bullets.map(b => (
            <li key={`bullet-${b.id}`}>{b.text}</li>
          ))}
        </ul>
      </div>

      <div className={classNames("desktop-col-6", style.findIndustryTools)}>
        <H className="h3 title" style={{ width: "100%", marginBottom: "50px" }}>
          {selectorTitle}
        </H>
        <div>
          {industries
            .filter(i => i.isActive === true)
            .filter(i => !i.showFactsPageOnly)
            .sort((firstIndustry, secondIndustry) => firstIndustry.sort - secondIndustry.sort)
            .map(industry => (
              <Link.Big
                key={industry.route}
                url={`/bransje/${industry.route}`}
                displayBlock
                style={{ marginBottom: "1rem" }}
              >
                {industry.title}
              </Link.Big>
            ))}
        </div>
      </div>
    </div>
  )
}

ToolDevGeneral.propTypes = {
  selectorTitle: PropTypes.string.isRequired,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
  bulletsTitle: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    })
  ).isRequired,
}

export default ToolDevGeneral
