import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"

const ControlLoop = ({ loop, activeId, onItemClick }) => {
  const getItem = id => {
    const item = loop.find(l => l.id === id)
    return { name: item.name, icon: item.icon }
  }

  const circleRadius = 27
  const iconSize = 24

  return (
    <svg
      className={styles.svg}
      width="600"
      height="384"
      viewBox="120 0 600 384"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <!-- Big arrow circle --> */}
      <path
        fill="white"
        d="M535.691 71.9375C505.294 41.5625 464.911 24.8125 421.969 24.8125V76.3125C482.263 76.3125 531.259 125.438 531.259 185.75C531.259 246.063 482.2 295.188 421.969 295.188C361.738 295.188 312.679 246.125 312.679 185.75C312.679 166.5 317.735 147.563 327.284 131C334.462 118.625 344.012 107.688 355.309 99L366.045 123.188L407.489 48.125L324.975 30.625L334.025 51C312.991 64.75 295.39 83.375 282.782 105.188C268.676 129.625 261.249 157.438 261.249 185.75C261.249 228.75 277.976 269.188 308.31 299.563C338.644 329.938 379.027 346.688 421.969 346.688C464.911 346.688 505.294 329.938 535.628 299.563C565.962 269.188 582.69 228.75 582.69 185.75C582.69 142.75 566.025 102.375 535.691 71.9375Z"
      />
      <path
        fill="#FFDD88"
        d="M527.888 67.5C497.554 37.125 468.406 21 425.464 21V79.625C485.758 79.625 524.767 121 524.767 181.375C524.767 241.75 475.709 290.812 415.477 290.812C355.246 290.812 311.805 247.312 311.805 186.938C311.805 167.688 316.861 148.75 326.41 132.188C333.588 119.812 344.386 107 355.683 98.3125L368.603 126.5L405.241 51.5L327.222 35.625L336.896 54.75C315.862 68.5 298.261 87.125 285.653 108.938C271.547 133.312 264.119 161.188 264.119 189.5C264.057 232.438 277.664 269.75 308.06 300.188C338.394 330.562 375.656 343.562 418.598 343.562C461.54 343.562 501.923 326.812 532.257 296.438C562.591 266.063 578.07 225.625 578.07 182.625C578.07 139.625 558.285 97.9375 527.888 67.5Z"
      />
      <path
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
        d="M535.691 71.9375C505.294 41.5625 464.911 24.8125 421.969 24.8125V76.3125C482.263 76.3125 531.259 125.438 531.259 185.75C531.259 246.063 482.2 295.188 421.969 295.188C361.738 295.188 312.679 246.125 312.679 185.75C312.679 166.5 317.735 147.563 327.284 131C334.462 118.625 344.012 107.688 355.309 99L366.045 123.188L407.489 48.125L324.975 30.625L334.025 51C312.991 64.75 295.39 83.375 282.782 105.188C268.676 129.625 261.249 157.438 261.249 185.75C261.249 228.75 277.976 269.188 308.31 299.563C338.644 329.938 379.027 346.688 421.969 346.688C464.911 346.688 505.294 329.938 535.628 299.563C565.962 269.188 582.69 228.75 582.69 185.75C582.69 142.75 566.025 102.375 535.691 71.9375Z"
      />

      <g onClick={() => onItemClick(1)} className={activeId === 1 ? styles.active : null}>
        <circle cx="515" cy="71" r={circleRadius} fill="white" stroke="black" strokeWidth="2" />
        <image x="503" y="59" width={iconSize} height={iconSize} href={getItem(1).icon} />
        <text x="561" y="75">
          {getItem(1).name}
        </text>
        <line x1="561" x2="662" y1="83" y2="83" stroke="black" strokeWidth="2" />
      </g>

      <g onClick={() => onItemClick(2)} className={activeId === 2 ? styles.active : null}>
        <circle cx="578" cy="192" r={circleRadius} fill="white" stroke="black" strokeWidth="2" />
        <image x="566" y="180" width={iconSize} height={iconSize} href={getItem(2).icon} />
        <text x="620" y="195">
          {getItem(2).name}
        </text>
        <line x1="620" x2="715" y1="203" y2="203" stroke="black" strokeWidth="2" />
      </g>

      <g onClick={() => onItemClick(3)} className={activeId === 3 ? styles.active : null}>
        <circle fill="white" cx="515" cy="301" r={circleRadius} stroke="black" strokeWidth="2" />
        <image x="503" y="289" width={iconSize} height={iconSize} href={getItem(3).icon} />
        <text x="560" y="310">
          {getItem(3).name}
        </text>
        <line x1="560" x2="683" y1="318" y2="318" stroke="black" strokeWidth="2" />
      </g>

      <g onClick={() => onItemClick(4)} className={activeId === 4 ? styles.active : null}>
        <circle fill="white" cx="320" cy="301" r={circleRadius} stroke="black" strokeWidth="2" />
        <image x="308" y="289" width={iconSize} height={iconSize} href={getItem(4).icon} />
        <text x="123" y="310">
          {getItem(4).name}
        </text>
        <line x1="123" x2="281" y1="318" y2="318" stroke="black" strokeWidth="2" />
      </g>

      <g onClick={() => onItemClick(5)} className={activeId === 5 ? styles.active : null}>
        <circle fill="white" cx="264" cy="149" r={circleRadius} stroke="black" strokeWidth="2" />
        <image x="252" y="137" width={iconSize} height={iconSize} href={getItem(5).icon} />
        <text x="163" y="155">
          {getItem(5).name}
        </text>
        <line x1="163" x2="225" y1="163" y2="163" stroke="black" strokeWidth="2" />
      </g>
    </svg>
  )
}

ControlLoop.propTypes = {
  activeId: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,
  loop: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ControlLoop
