/* eslint-disable react/no-danger */
import React from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { Button, BlockContent } from "../../../components"
import HowToUse from "./_howToUse"
import { H } from "../../../state/levelContext"
import { eventLogger } from "../../../utils"

const Tool = ({
  title,
  content,
  isMobile,
  imageSource,
  altText,
  buttonURL,
  buttonText,
  accordionItems,
  style,
  className,
  external,
  clickEventName,
  onClick,
  twoButtons,
  BtnOvernattingText,
  BtnServeringText,
  BransjeOvernattingUrl,
  BransjeServeringUrl,
}) => {
  const toolsStyling = classNames(styles.toolContainer, className)
  const location = useLocation()
  let btn1Text = BtnOvernattingText
  let btn2Text = BtnServeringText

  if (btn1Text !== null) {
    btn1Text = btn1Text.replace("Overnatting", "<b>Overnatting</b>")
    btn1Text = btn1Text.replace("Servering", "<b>Servering</b>")
  }

  if (btn2Text !== null) {
    btn2Text = btn2Text.replace("Overnatting", "<b>Overnatting</b>")
    btn2Text = btn2Text.replace("Servering", "<b>Servering</b>")
  }

  const roundBtn = (
    <div className="center-content">
      <Button.Primary
        url={buttonURL}
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
        // eslint-disable-next-line no-nested-ternary
        type={onClick ? "button" : external ? "external" : "internal"}
        icon={external ? "external" : "arrow"}
        onClick={onClick}
        buttonClickLogEvent={() => {
          if (clickEventName) {
            eventLogger.logCustomEvent(location, clickEventName)
          }
        }}
        buttonClickAnalyticsEvent={() => {
          window._sz?.push(["event", "File Download", "Click/Download", buttonText])
        }}
      >
        {buttonText}
      </Button.Primary>
    </div>
  )

  const deskBtn = isMobile ? "" : roundBtn
  const mobileBtn = isMobile ? roundBtn : ""
  const titleTop = isMobile ? "16px" : "0px"

  return (
    <div className={toolsStyling} style={style}>
      <div className={styles.alignAccordion}>
        <div className={styles.backdrop} />

        {isMobile ? (
          ""
        ) : (
          <div className="center-content" style={{ marginTop: "50px" }}>
            <div className={styles.alignImage}>
              <img src={imageSource} alt={altText} />
            </div>
          </div>
        )}

        <H className="h1 title center-text" style={{ marginTop: titleTop }}>
          {title}
        </H>
        {twoButtons ? (
          <div className="center-content">
            <Button.Smaller
              url={BransjeOvernattingUrl}
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                marginRight: "20px",
                marginLeft: "20px",
                whiteSpace: "normal",
              }}
              type={external ? "external" : "internal"}
              icon={external ? "external" : "arrow"}
              buttonClickLogEvent={() => {
                if (clickEventName) {
                  eventLogger.logCustomEvent(location, clickEventName)
                }
              }}
              buttonClickAnalyticsEvent={() => {
                window._sz?.push(["event", "File Download", "Click/Download", btn1Text])
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: btn1Text }} />
            </Button.Smaller>
            <Button.Smaller
              url={BransjeServeringUrl}
              style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                marginRight: "20px",
                marginLeft: "20px",
                whiteSpace: "normal",
              }}
              type={external ? "external" : "internal"}
              icon={external ? "external" : "arrow"}
              buttonClickLogEvent={() => {
                if (clickEventName) {
                  eventLogger.logCustomEvent(location, clickEventName)
                }
              }}
              buttonClickAnalyticsEvent={() => {
                window._sz?.push(["event", "File Download", "Click/Download", btn2Text])
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: btn2Text }} />
            </Button.Smaller>
          </div>
        ) : (
          deskBtn
        )}
        <div className={styles.textBlock}>
          <BlockContent blocks={content || []} />
        </div>
        {mobileBtn}
      </div>
      <div className={classNames("content-item")} style={{ padding: "0", marginBottom: "0" }}>
        <HowToUse.Accordion
          toolTitle={title}
          items={accordionItems}
          backgroundColor="yellow"
          border="yellow"
        />
      </div>
    </div>
  )
}

Tool.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.any).isRequired,
  buttonURL: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isMobile: PropTypes.string.isRequired,
  imageSource: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  accordionItems: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.any).isRequired,
    })
  ).isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  external: PropTypes.bool,
  clickEventName: PropTypes.string,
  onClick: PropTypes.func,
  twoButtons: PropTypes.bool,
  BtnOvernattingText: PropTypes.string,
  BtnServeringText: PropTypes.string,
  BransjeOvernattingUrl: PropTypes.string,
  BransjeServeringUrl: PropTypes.string,
}

Tool.defaultProps = {
  style: {},
  external: false,
  className: null,
  clickEventName: null,
  onClick: null,
  twoButtons: false,
  BtnOvernattingText: null,
  BtnServeringText: null,
  BransjeOvernattingUrl: null,
  BransjeServeringUrl: null,
}

export default Tool
