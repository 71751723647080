import React, { useState } from "react"
import useSanity from "../hooks/useSanity"
import { BackLink, Title, Chapter, Loading } from "../components"
import SEO from "../components/SEO"
import { IndustryType } from "../propTypes"
import withPageDependencies from "../hoc/withPageDependencies"
import { blockContentGroq } from "../groq"

const Bedriftshelsetjenesten = ({ industry }) => {
  const [params] = useState({ id: "bhtPage" })
  const { loading, error, data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
          ...,
          chapterItems[] {
            ...,
            content[]  ${blockContentGroq}
          },
        } | order(_updatedAt desc) [0]`,
    params
  )

  const page = data

  if (loading || error) return <Loading error={error} />
  return (
    <>
      <SEO title={page.title} description={page.metaDescription} />
      <Title.Default title={page.title} description={page.ingress} />
      {page.chapterItems.map(chapter => (
        <Chapter key={chapter._key} title={chapter.title} content={chapter.content} />
      ))}
      <BackLink to={industry ? `/bransje/${industry.route}` : "/"} />
    </>
  )
}

Bedriftshelsetjenesten.propTypes = {
  industry: IndustryType,
}

Bedriftshelsetjenesten.defaultProps = {
  industry: null,
}
export default withPageDependencies(Bedriftshelsetjenesten)
