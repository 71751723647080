import React from "react"
import PropTypes from "prop-types"
import Content from "./_content"
import styles from "./style.module.scss"

const HowToUse = ({ toolTitle, style, items, backgroundColor, border }) => {
  return (
    <div className={styles.container} style={style}>
      {items.map(({ title, content }) => {
        return (
          <Content
            key={`accordionItem-${title}`}
            id={`${toolTitle}_${title}`}
            title={title}
            content={content}
            backgroundColor={backgroundColor}
            border={border}
          />
        )
      })}
    </div>
  )
}

HowToUse.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.any).isRequired,
    })
  ).isRequired,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  toolTitle: PropTypes.string.isRequired,
}

HowToUse.defaultProps = {
  style: null,
  backgroundColor: "yellow",
  border: "yellow",
}

export default HowToUse
