import React from "react"
import PropTypes from "prop-types"
import layout from "./layout.module.scss"
import { BlockContent } from "../../../components"

const BottomFacts = ({ imageSource, imageAltText, blockContent }) => {
  return (
    <div className={layout.flex}>
      <img className={layout.flexItem} src={imageSource} alt={imageAltText} />
      <div className={layout.flexItem}>
        <BlockContent blocks={blockContent || []} />
      </div>
    </div>
  )
}

BottomFacts.propTypes = {
  imageSource: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  blockContent: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default BottomFacts
