import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import { Button } from "../../../components"
import { H } from "../../../state/levelContext"

const Header = ({
  icon,
  title,
  nextBtnText,
  prevBtnText,
  onNextBtnClick,
  onPrevBtnClick,
  hidePrevBtn,
  hideNextBtn,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerTag}>
        <img src={icon} alt="" className={styles.icon} />
        <H className="h5">{title}</H>
      </div>
      <div className={styles.headerButtons} style={hidePrevBtn ? { alignSelf: "flex-end" } : null}>
        {!hidePrevBtn && (
          <Button.Primary
            className={styles.rightToLeft}
            type="button"
            onClick={onPrevBtnClick}
            icon="arrow"
          >
            {prevBtnText}
          </Button.Primary>
        )}
        {!hideNextBtn && (
          <Button.Primary
            type="button"
            style={{ marginLeft: "2rem" }}
            onClick={onNextBtnClick}
            icon="arrow"
          >
            {nextBtnText}
          </Button.Primary>
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  nextBtnText: PropTypes.string,
  prevBtnText: PropTypes.string,
  onNextBtnClick: PropTypes.func,
  onPrevBtnClick: PropTypes.func,
  hideNextBtn: PropTypes.bool,
  hidePrevBtn: PropTypes.bool,
}

Header.defaultProps = {
  nextBtnText: "Neste",
  prevBtnText: "Forrige",
  onNextBtnClick: () => {},
  onPrevBtnClick: () => {},
  hideNextBtn: false,
  hidePrevBtn: false,
}
export default Header
