import React from "react"
import PropTypes from "prop-types"

const PartsLogo = ({ partners }) => {
  return (
    <div className="content center-content" style={{ padding: "80px" }}>
      {partners.map(partner => (
        <div key={partner.image.asset.url} className="desktop-col center-content">
          <a href={partner.href} target="_blank" rel="noopener external">
            <img src={partner.image.asset.url} alt={partner.image.alt} />
          </a>
        </div>
      ))}
    </div>
  )
}

PartsLogo.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      image: PropTypes.shape({
        asset: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default PartsLogo
