import React, { useState, useEffect } from "react"
import SEO from "../../components/SEO"
import { Accordion, BackLink, Title, Loading } from "../../components"
import { IndustryType } from "../../propTypes"
import { withDefaults } from "../../utils"
import withPageDependencies from "../../hoc/withPageDependencies"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"

const VanligeSporsmal = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "commonQuestionsPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->route in [$industryRoute, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          route,
        },
        ingress[] ${blockContentGroq},
        accordionItems[] {
          ...,
          content[] ${blockContentGroq},
        }
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.route)
  return (
    <>
      <SEO title={page.title} />
      <Title.Default title={page.title} content={page.ingress} />
      <Accordion.Default
        items={page.accordionItems}
        portableContent
        itemStyle={{ marginBottom: "2rem" }}
      />
      <BackLink to={`/bransje/${industry.route}`} />
    </>
  )
}

VanligeSporsmal.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(VanligeSporsmal)
