import React from "react"
import PropTypes from "prop-types"
import { ScrollDown, BlockContent } from "../../../components"
import styles from "./style.module.scss"

function GoodWorkingEnv({ title, content, imageSource, altText }) {
  return (
    <section className={styles.goodWorkingEnv} aria-label={title}>
      <div className={styles.goodWorkingEnvTextAndImage}>
        <div className={styles.goodWorkingEnvText}>
          <h1 className="title">{title}</h1>
          <span className="h5">
            <BlockContent blocks={content} />
          </span>
        </div>
        <div className={styles.goodWorkingEnvImage}>
          <img src={imageSource} alt={altText} />
        </div>
      </div>
      <div className="content" style={{ paddingBottom: "0" }}>
        <ScrollDown.Yellow />
      </div>
    </section>
  )
}

GoodWorkingEnv.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  imageSource: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
}

export default GoodWorkingEnv
