import React, { useState, useEffect } from "react"
import { isMobile } from "react-device-detect"
import { BackLink, IllustrationLink, Title, BlockContent, Loading } from "../../../components"
import SEO from "../../../components/SEO"
import Tool from "./_tool"
import styles from "./style.module.scss"
import { IndustryType } from "../../../propTypes"
import { withDefaults } from "../../../utils"
// import { PETROLEUM_ID } from "../../../../sanity/migrations/seedData/helpers/constants"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { H, Section } from "../../../state/levelContext"

const VerktoyPage = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "verktoyPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->route in [$industryRoute, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          ...
        },
        ingress[] ${blockContentGroq},
        noToolsIngress[] ${blockContentGroq},
        controlLoop {
          ...,
          image {
            ...,
            asset-> {
              url
            }
          },
          ingress[] ${blockContentGroq}
        },
        toolEbdpjImage {
          ...,
          asset-> {
            url
          },
          alt
        },
        toolEbdpjContent[] ${blockContentGroq},
        toolEbdpjContentMobile[] ${blockContentGroq},
        toolEbdpjAccordionItems[] {
          ...,
          content[] ${blockContentGroq}
        },
        toolHjelpenImage {
          ...,
          asset-> {
            url
          },
          alt
        },
        toolHjelpenContent[] ${blockContentGroq},
        toolHjelpenContentMobile[] ${blockContentGroq},
        toolHjelpenAccordionItems,
        toolHjelpenTwoButtons,
        toolHjelpenBtnOvernatting,
        toolHjelpenBtnServering,
        toolHjelpenBransjeOvernatting,
        toolHjelpenBransjeServering,
        tipsImage {
          ...,
          asset-> {
            url
          },
          alt
        }
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.route)

  let _toolEbdpjImageUrl = ""
  if (page.toolEbdpjImage.asset !== undefined) {
    _toolEbdpjImageUrl = page.toolEbdpjImage.asset.url
  }
  let _toolHjelpenImageUrl = ""
  if (page.toolHjelpenImage.asset !== undefined) {
    _toolHjelpenImageUrl = page.toolHjelpenImage.asset.url
  }

  const imageBlock = (
    <div className={styles.imgColumns}>
      <div className={styles.imgColumn_left}>
        <div className={styles.imgBackdrop} />
        <img src={_toolEbdpjImageUrl} alt={page.toolEbdpjImage.alt} />
      </div>
      <div className={styles.imgColumn_right}>
        <div className={styles.imgBackdrop} />
        <img src={_toolHjelpenImageUrl} alt={page.toolHjelpenImage.alt} />
      </div>
    </div>
  )

  const titleBlock = (
    <div
      className="content"
      style={{
        paddingBottom: "3rem",
        paddingTop: "3rem",
      }}
    >
      <div className={isMobile ? "desktop-col-10" : "desktop-col-10 center-text"}>
        <H className="h1">{page.title}</H>
        {isMobile ? imageBlock : ""}
        <BlockContent blocks={page.ingress || []} />
      </div>
    </div>
  )
  const noToolTitleBlock = <Title.Default title={page.noToolsTitle} content={page.noToolsIngress} />

  return (
    <>
      <SEO title="Verktøy" />
      <Section>
        {industry.tools.length !== 0 ? titleBlock : noToolTitleBlock}

        <div className="content" style={{ padding: "1rem" }}>
          {industry.tools.includes("bradag") ? (
            <div className="desktop-col-6">
              <div className={styles.toolItem}>
                <Section>
                  <Tool
                    title={page.toolEbdpjTitle}
                    content={isMobile ? page.toolEbdpjContentMobile : page.toolEbdpjContent}
                    isMobile={isMobile}
                    imageSource={_toolEbdpjImageUrl}
                    altText={page.toolEbdpjImage.alt}
                    buttonURL={industry.bradag_url}
                    accordionItems={page.toolEbdpjAccordionItems}
                    buttonText={page.toolEbdpjButtonText}
                    className={styles.toolItem}
                    external
                    clickEventName="at.events.clicks.bradag"
                  />
                </Section>
              </div>
            </div>
          ) : null}
          {industry.tools.includes("hjelpen") ? (
            <div className="desktop-col-6">
              <div className={styles.toolItem}>
                <Tool
                  title={page.toolHjelpenTitle}
                  content={isMobile ? page.toolHjelpenContentMobile : page.toolHjelpenContent}
                  isMobile={isMobile}
                  imageSource={_toolHjelpenImageUrl}
                  altText={page.toolHjelpenImage.alt}
                  buttonURL={`${industry.hjelpen_url}`}
                  buttonText={page.toolHjelpenButtonText}
                  accordionItems={page.toolHjelpenAccordionItems}
                  external
                  clickEventName="at.events.clicks.hjelpen"
                  twoButtons={page.toolHjelpenTwoButtons}
                  BtnOvernattingText={page.toolHjelpenBtnOvernatting}
                  BtnServeringText={page.toolHjelpenBtnServering}
                  BransjeOvernattingUrl={`${page.toolHjelpenBransjeOvernatting}`}
                  BransjeServeringUrl={`${page.toolHjelpenBransjeServering}`}
                />
              </div>
            </div>
          ) : null}
        </div>

        <h2 className="center-text" style={{ marginTop: "5rem", marginBottom: "5rem" }}>
          {page.relatedTitle}
        </h2>

        {industry.tools.includes("styringssloyfen") ? (
          <IllustrationLink.Large
            title={page.controlLoop.title}
            content={page.controlLoop.ingress}
            linkText={page.controlLoop.buttonText}
            url={`/bransje/${industry.route}/styringssloyfen`}
            imageSource={page.controlLoop.image.asset.url}
            imageAltText={page.controlLoop.image.alt}
            background="yellow"
            portableContent
            type="secondary"
          />
        ) : null}

        {industry.tools.includes("styringssloyfen") ? (
          <div className="center-text" style={{ marginTop: "5rem", marginBottom: "5rem" }} />
        ) : null}

        <IllustrationLink.Large
          title={page.tipsTitle}
          linkText={page.tipsLinkText}
          url={`/bransje/${industry.route}/tips-og-rad`}
          imageSource={page.tipsImage.asset.url}
          imageAltText={page.tipsImage.alt}
          background="yellow"
          illustrationOverflowY
        />

        <BackLink to={`/bransje/${industry.route}`} />
      </Section>
    </>
  )
}

VerktoyPage.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(VerktoyPage)
