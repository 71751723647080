/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { Button, BlockContent } from "../../components"

const ContactOption = ({
  title,
  content,
  buttonURL,
  buttonText,
  style,
  className,
  external,
  icon,
}) => {
  const contactsStyling = classNames("desktop-flexcol", styles.contactContainer, className)
  return (
    <div className={contactsStyling} style={style}>
      <div>
        <h3 className="title" style={{ marginLeft: "5rem", marginRight: "5rem" }}>
          {title}
        </h3>
      </div>

      <div style={{ marginTop: "auto" }}>
        <div style={{ marginLeft: "5rem", marginRight: "5rem" }}>
          <BlockContent blocks={content} />
        </div>
        <Button.Primary
          url={buttonURL}
          style={{ margin: "5rem" }}
          type={external ? "external" : "internal"}
          icon={icon}
        >
          {buttonText}
        </Button.Primary>
      </div>
    </div>
  )
}

ContactOption.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonURL: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  external: PropTypes.bool,
  icon: PropTypes.string,
}

ContactOption.defaultProps = {
  style: {},
  external: false,
  className: null,
  icon: null,
  buttonURL: "",
}

export default ContactOption
