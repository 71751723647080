/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import { BlockContent } from "../../../components"
import styles from "./style.module.scss"

const AccordionContent = ({ icon, iconAlt, title, content }) => {
  return (
    <div>
      <div className={styles.accordionHeader}>
        <img src={icon} alt={iconAlt} className={styles.accordionIcon} />
        <h3 style={{ marginLeft: "3rem" }}>{title}</h3>
      </div>
      <BlockContent blocks={content || []} />
    </div>
  )
}

AccordionContent.propTypes = {
  icon: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AccordionContent
