import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { BlockContent } from "../../components"
import { Section } from "../../state/levelContext"

const Quote = ({ title, ingress }) => {
  return (
    <section className={classNames("content", styles.quote)}>
      <div className={classNames("desktop-col-1 tablet-col-1 mobile-col-1", styles.quoteMark)} />
      <div className="desktop-col-8 tablet-col-8 mobile-col-8">
        <cite className="h1" aria-level={1}>
          {title}
        </cite>
      </div>
      <Section>
        <div className="desktop-col-12 tablet-col-12 mobile-col-12 center-content">
          <BlockContent blocks={ingress} />
        </div>
      </Section>
    </section>
  )
}

Quote.propTypes = {
  title: PropTypes.string.isRequired,
  ingress: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Quote
