import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
  FrontpageIndustrySelector,
  GoodWorkingEnv,
  Quote,
  Factors,
  SimpleMeasures,
} from "./_index/_index"
import { FindIndustryFullWidth, VideoAndText, Loading, IllustrationLink } from "../components"
import { blockContentGroq } from "../groq"
import SEO from "../components/SEO"
import style from "./_index/style.module.scss"
import { IndustryType } from "../propTypes"
import withPageDependencies from "../hoc/withPageDependencies"
import useSanity from "../hooks/useSanity"
import { Section } from "../state/levelContext"

const IndexPage = ({ industries }) => {
  const [params] = useState({
    landingPageId: "landingPage",
    videoAndTextModuleId: "videoAndTextModule",
  })

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_id==$landingPageId || _id=="drafts."+$landingPageId]
      {
        ...,
        benefits[] {
          ...,
          benefitIcon {
            ...,
            asset->,
          }
        },
        environmentImage {
          ...,
          asset->
        },
        factors[] {
          ...,
          icon {
            ...,
            asset->
          }
        },
        factorsIngress[] ${blockContentGroq},
        knowledge {
          ...,
          image {
            ...,
            asset-> {
              url
            }
          }
        },
      } | order(_updatedAt desc) [0],
      "video": *[_id==$videoAndTextModuleId || _id=="drafts."+$videoAndTextModuleId]
      {
        ...,
        videoPreview {
          ...,
          asset->,
        }
      } | order(_updatedAt desc) [0]
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = data.pageData
  const { video } = data
  const benefitItems = page.benefits.map(item => {
    const newItem = {
      title: item.title,
      imageAlt: item.benefitIcon.alt,
      imageSrc: item.benefitIcon.asset.url,
    }
    return newItem
  })

  const factorItems = page.factors.map(item => {
    const newFactor = {
      title: item.title,
      contentTitle: item.contentTitle,
      icon: item.icon.asset.url,
      content: item.content,
      id: item._key,
      _key: item._key,
    }
    return newFactor
  })

  const suggestions = page.findIndustrySuggestions?.map(s => s.value) ?? []

  return (
    <>
      <SEO title="Hjem" description={page.metaDescription} />
      <div className={classNames(style.backdrop)} />
      <div className={classNames(style.frontpageContent)}>
        <div className={classNames("desktop-col-12", "center-content")}>
          <GoodWorkingEnv
            title={page.environmentTitle}
            content={page.environmentIngress}
            imageSource={page.environmentImage.asset.url}
            altText={page.environmentImage.alt}
          />
        </div>

        <div
          className={classNames("desktop-col-12", "center-content", style.displayColSmallScreen)}
        >
          <FrontpageIndustrySelector
            title={page.findIndustryTitle}
            industries={industries}
            suggestions={suggestions}
          />
        </div>
      </div>

      <Section>
        <VideoAndText
          id="introduksjonsfilm"
          title={video.title}
          videoURL={video.videoURL}
          previewImage={video.videoPreview.asset.url}
          sideText={video.sideText}
        />
      </Section>

      <Section>
        <Quote title={page.quoteTitle} ingress={page.quoteIngress} />
      </Section>

      <Section>
        <Factors title={page.factorsTitle} ingress={page.factorsIngress} items={factorItems} />
      </Section>

      <Section>
        <section aria-label={page.knowledge.title}>
          <IllustrationLink.Large
            title={page.knowledge.title}
            content={page.knowledge.ingress}
            linkText={page.knowledge.buttonText}
            url="/kunnskapsmodulen"
            imageSource={page.knowledge.image.asset.url}
            imageAltText={page.knowledge.image.alt}
            style={{ marginTop: "4rem" }}
            portableContent
          />
        </section>
      </Section>

      <Section>
        <SimpleMeasures
          title={page.benefitsTitle}
          description={page.benefitsIngress}
          measures={benefitItems}
        />
      </Section>

      <Section>
        <FindIndustryFullWidth
          title={page.findIndustryTitle}
          text={page.findIndustryIngress}
          industries={industries}
        />
      </Section>
    </>
  )
}
IndexPage.propTypes = {
  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

IndexPage.defaultProps = {}

export default withPageDependencies(IndexPage)
