import React from "react"
import PropTypes from "prop-types"

const RouterWrapper = ({ children }) => {
  return <>{children}</>
}

RouterWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

export default RouterWrapper
