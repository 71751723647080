import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { BlockContent } from "../../../components"
import { H, Section } from "../../../state/levelContext"

const StatisticsHero = ({ statisticsTexts }) => {
  const horizontalLineStyling = classNames(
    "tablet-display-none",
    "desktop-display-none",
    styles.horizontalLine
  )

  return (
    <>
      <div className={styles.headerBorderNoFill}>
        <H className="center-text h2">{statisticsTexts.preventionTitle}</H>
        <p className={styles.statisticsLabel}>
          {statisticsTexts.preventionPercent}
          <span className={styles.statisticsLabelPercent}>%</span>
        </p>

        <Section>
          <div className="center-text" style={{ margin: "2rem" }}>
            <BlockContent blocks={statisticsTexts.preventionIngress} />
          </div>
        </Section>
        <ul className={styles.headerList}>
          {statisticsTexts.preventionList.map(item => {
            return <li key={item}>{item}</li>
          })}
        </ul>
        <hr className={horizontalLineStyling} />
      </div>
    </>
  )
}

StatisticsHero.propTypes = {
  statisticsTexts: PropTypes.shape({
    preventionTitle: PropTypes.string.isRequired,
    preventionPercent: PropTypes.number.isRequired,
    preventionIngress: PropTypes.arrayOf(PropTypes.object).isRequired,
    preventionList: PropTypes.arrayOf(PropTypes.string).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
  }).isRequired,
}

export default StatisticsHero
