import React, { useState, useEffect } from "react"
import { navigate, useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { IllustrationLink, Hero, ScrollDown, VideoAndText, Loading } from "../../components"
import ToolDevGeneral from "./_toolDevGeneral"
import SEO from "../../components/SEO"
import { withDefaults, eventLogger } from "../../utils"
import withPageDependencies from "../../hoc/withPageDependencies"
import { IndustryType } from "../../propTypes"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"
import { Section } from "../../state/levelContext"

const BransjeMainPage = ({ industry, industries }) => {
  const [params, setParams] = useState(-1)
  const location = useLocation()
  const faktaOmBransjenClickEvent = "at.events.clicks.faktaombransjen"
  const verktoyClickEvent = "at.events.clicks.verktoy"

  useEffect(() => {
    setParams({
      type: "mainPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->route in [$industryRoute, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
    			title,
          route,
          tools
        },
        "isPetroleum": coalesce(isPetroleum, false),
				heroIngress[] ${blockContentGroq},
				toolsImage {
          ...,
          asset-> {
          	url
        	}
        },
        bhtImage {
          ...,
          asset-> {
          	url
        	}
        },
				toolsIngress[] ${blockContentGroq},
        bhtIngress[] ${blockContentGroq},
				videoText[] ${blockContentGroq},
				videoPreviewImage {
          ...,
          asset-> {
          	url
        	}
        },
				rolesImage {
          ...,
          asset-> {
          	url
        	}
        },
				rolesBottomContent[] ${blockContentGroq},
				advicesImage {
          ...,
          asset-> {
          	url
        	}
        },
				advicesBottomContent[] ${blockContentGroq},
				navMinSideIngress[] ${blockContentGroq},
				navMinSideImage {
          ...,
          asset-> {
          	url
        	}
        },
				supportIngress[] ${blockContentGroq},
				supportImage {
          ...,
          asset-> {
          	url
        	}
        },
				controlLoop {
          ...,
          image {
          	...,
            asset-> {
              url
            }
					},
					ingress[] ${blockContentGroq},
        },
        "upcomingBullets": coalesce(upcomingBullets, []),
        "upcomingBulletsTitle": coalesce(upcomingBulletsTitle, ""),
        "upcomingSelectorTitle": coalesce(upcomingSelectorTitle, ""),
        "videoPreviewImage": coalesce(videoPreviewImage { ..., asset-> { url } }, ""),
        "videoText": coalesce(videoText[] ${blockContentGroq}, []),
        "videoURL": coalesce(videoURL, ""),
      }`,
    params
  )

  if (industry.showFactsPageOnly) {
    navigate(`/bransje/${industry.route}/fakta-om-bransjen`)
  }

  if (loading || error) return <Loading error={error} />
  const page = withDefaults(data, industry.route)
  const getToolsModule = () => {
    return (
      <div style={{ marginTop: "4rem" }}>
        <ScrollDown.White />
        <IllustrationLink.Large
          title={page.toolsTitle}
          content={page.toolsIngress}
          linkText={page.toolsButtonText}
          url={`/bransje/${industry.route}/verktoy`}
          imageSource={page.toolsImage.asset.url}
          imageAltText={page.toolsImage.alt}
          illustrationOverflowY
          background="yellow"
          isTool
          portableContent
          buttonClickLogEvent={() => {
            eventLogger.logCustomEvent(location, verktoyClickEvent)
          }}
        />
      </div>
    )
  }
  const getBthModule = () => {
    return (
      <div style={{ paddingTop: "8rem" }}>
        <IllustrationLink.Large
          title={page.bhtTitle}
          content={page.bhtIngress}
          linkText={page.bhtButtonText}
          url={`/bedriftshelsetjenesten?bransje=${industry.route}`}
          imageSource={page.bhtImage.asset.url}
          imageAltText={page.bhtImage.alt}
          background="white"
          border="yes"
          portableContent
          buttonClickLogEvent={() => {
            // eventLogger.logCustomEvent(location, verktoyClickEvent)
          }}
        />
      </div>
    )
  }

  return (
    <>
      <SEO title={industry.title} />
      <Section>
        <Hero
          title={page.heroTitle}
          content={page.heroIngress}
          image={industry.heroImage.asset.url}
          altImageText={industry.heroImage.alt}
          industryName={industry.title}
          buttonText={
            industry.skipPages.find(skipPage => skipPage === "fakta-om-bransjen")
              ? null
              : page.heroButtonText
          }
          buttonLink={`/bransje/${industry.route}/fakta-om-bransjen`}
          portableContent
          buttonClickLogEvent={() => {
            eventLogger.logCustomEvent(location, faktaOmBransjenClickEvent)
          }}
        />
      </Section>
      {page.industry.tools.includes("styringssloyfen") && (
        // Only show control loop on petroleum
        <Section>
          <IllustrationLink.Large
            style={{ marginTop: "8rem" }}
            title={page.controlLoop.title}
            content={page.controlLoop.ingress}
            linkText={page.controlLoop.buttonText}
            url={`/bransje/${industry.route}/styringssloyfen`}
            imageSource={page.controlLoop.image.asset.url}
            imageAltText={page.controlLoop.image.alt}
            background="yellow"
            portableContent
            type="secondary"
          />
        </Section>
      )}

      <Section>{getToolsModule()}</Section>

      {page.videoPreviewImage && page.videoText && page.videoURL && (
        <Section>
          <VideoAndText
            id="bransjevideo"
            videoURL={page.videoURL ? page.videoURL : ""}
            sideText={page.videoText}
            previewImage={page.videoPreviewImage ? page.videoPreviewImage.asset.url : ""}
          />
        </Section>
      )}

      <div className="content" style={{ marginTop: "4rem" }}>
        {!industry.skipPages.includes("roller-og-samarbeid") && (
          <div className="desktop-col-4">
            <Section>
              <IllustrationLink.Small
                imageSource={page.rolesImage.asset.url}
                imageAlt={page.rolesImage.alt}
                title={page.rolesTitle}
                linkText={page.rolesLinkText}
                bottomContent={page.rolesBottomContent}
                url={`/bransje/${industry.route}/roller-og-samarbeid`}
                titleStyle={{ minHeight: "80px" }}
              />
            </Section>
          </div>
        )}

        {!industry.skipPages.includes("tips-og-rad") && (
          <div className="desktop-col-4">
            <Section>
              <IllustrationLink.Small
                imageSource={page.advicesImage.asset.url}
                imageAlt={page.advicesImage.alt}
                title={page.advicesTitle}
                linkText={page.advicesLinkText}
                bottomContent={page.advicesBottomContent}
                url={`/bransje/${industry.route}/tips-og-rad`}
                titleStyle={{ minHeight: "80px" }}
              />
            </Section>
          </div>
        )}
      </div>

      {!industry.skipPages.includes("bedriftshelsetjenesten") && (
        <Section>{getBthModule()}</Section>
      )}

      <div style={{ marginTop: "4rem", marginBottom: "4rem" }}>
        {!industry.skipPages.includes("sykefravaersstatistikk") && (
          <Section>
            <IllustrationLink.Large
              title={page.navMinSideTitle}
              content={page.navMinSideIngress}
              linkText={page.navMinSideLinkText}
              url={page.navMinSideLinkURL}
              imageSource={page.navMinSideImage.asset.url}
              imageAltText={page.navMinSideImage.alt}
              background="yellow"
              type="secondary"
              external
              portableContent
            />
          </Section>
        )}
        {!industry.skipPages.includes("bistand-og-ressurser") && (
          <Section>
            <IllustrationLink.Large
              title={page.supportTitle}
              content={page.supportIngress}
              linkText={page.supportLinkText}
              url={`/bransje/${industry.route}/bistand-og-ressurser`}
              imageSource={page.supportImage.asset.url}
              imageAltText={page.supportImage.alt}
              style={{ marginTop: "4rem" }}
              portableContent
            />
          </Section>
        )}
      </div>
      <Section>
        {page.upcomingBulletsTitle ? (
          <ToolDevGeneral
            bulletsTitle={page.upcomingBulletsTitle}
            bullets={page.upcomingBullets.map((b, index) => {
              return { id: index, text: b }
            })}
            selectorTitle={page.upcomingSelectorTitle}
            industries={industries}
          />
        ) : null}
      </Section>
    </>
  )
}

BransjeMainPage.propTypes = {
  industries: PropTypes.arrayOf(IndustryType).isRequired,
  industry: IndustryType.isRequired,
}

export default withPageDependencies(BransjeMainPage)
