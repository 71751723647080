import React, { useState, useEffect } from "react"
import { IndustryType } from "../../../propTypes"
import { BackLink, Title, IllustrationLink, Loading } from "../../../components"
import SEO from "../../../components/SEO"
import Header from "./_header"
import LoopContent from "./_loopContent"
import ControlLoop from "./_controlLoop"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { H, Section } from "../../../state/levelContext"

const StyringssloyfenPage = ({ industry }) => {
  const [activeId, setActiveId] = useState(1)
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "controlLoopPage",
      industryRoute: industry.route,
    })
  }, [industry])

  const {
    loading,
    error,
    data: page,
  } = useSanity(
    `*[_type==$type &&
      industry->route in [$industryRoute, "default"] &&
      count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          route,
        },
        relatedIllustrationLink {
          ...,
          image {
            ...,
            asset-> {
              url
            }
          }
        },
				identifisering_icon {
          ...,
          asset-> {
          	url
        	},
        },
        identifiseringContent[] ${blockContentGroq},
				kartlegging_icon {
          ...,
          asset-> {
          	url
        	},
        },
        kartleggingContent1[] ${blockContentGroq},
        kartlegging_accordions[] {
          ...,
          content[] ${blockContentGroq},
          icon {
            ...,
            asset-> {
              url
            },
          },
        },
        kartleggingContent2[] ${blockContentGroq},
        kartlegging_illustrationLink {
          ...,
          image {
            ...,
            asset-> {
              url
            }
          }
        },
				risikovurdering_icon {
          ...,
          asset-> {
          	url
        	},
        },
        risikovurdering_content[] ${blockContentGroq},

				tiltak_og_verifikasjon_icon {
          ...,
          asset-> {
          	url
        	},
        },
        tiltak_og_verifikasjon_content1[] ${blockContentGroq},
				tiltak_og_verifikasjon_illustration_image {
          ...,
          asset-> {
          	url
        	},
        },
        tiltak_og_verifikasjon_content2[] ${blockContentGroq},
        kontroll_icon {
          ...,
          asset-> {
          	url
        	},
        },
        kontroll_content[] ${blockContentGroq},
      } [0]`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const loop = [
    {
      id: 1,
      tag: page.identifisering_tag,
      name: page.identifisering_name,
      title: page.identifisering_title,
      icon: page.identifisering_icon.asset.url,
      content: page.identifisering_content,
    },
    {
      id: 2,
      tag: page.kartlegging_tag,
      name: page.kartlegging_name,
      title: page.kartlegging_title,
      icon: page.kartlegging_icon.asset.url,
      content1: page.kartlegging_content1,
      accordions: page.kartlegging_accordions,
      content2: page.kartlegging_content2,
      buttonText: page.kartlegging_buttonText,
      buttonURL: page.kartlegging_buttonURL,
      illustrationLink: {
        title: page.kartlegging_illustrationLink.title,
        ingress: page.kartlegging_illustrationLink.ingress,
        image: page.kartlegging_illustrationLink.image.asset.url,
        imageAlt: page.kartlegging_illustrationLink.image.alt,
      },
    },
    {
      id: 3,
      tag: page.risikovurdering_tag,
      name: page.risikovurdering_name,
      title: page.risikovurdering_title,
      icon: page.risikovurdering_icon.asset.url,
      content: page.risikovurdering_content,
    },
    {
      id: 4,
      tag: page.tiltak_og_verifikasjon_tag,
      name: page.tiltak_og_verifikasjon_name,
      title: page.tiltak_og_verifikasjon_title,
      icon: page.tiltak_og_verifikasjon_icon.asset.url,
      content1: page.tiltak_og_verifikasjon_content1,
      illustration: {
        text: page.tiltak_og_verifikasjon_illustration_text,
        image: page.tiltak_og_verifikasjon_illustration_image.asset.url,
        imageAlt: page.tiltak_og_verifikasjon_illustration_image.alt,
        imageTooltip: page.tiltak_og_verifikasjon_illustration_imageTooltip,
      },
      content2: page.tiltak_og_verifikasjon_content2,
    },
    {
      id: 5,
      tag: page.kontroll_tag,
      name: page.kontroll_name,
      title: page.kontroll_title,
      icon: page.kontroll_icon.asset.url,
      content: page.kontroll_content,
    },
  ]

  const currentLoopContent = loop.find(l => l.id === activeId)

  return (
    <>
      <SEO title="Styringssløyfen" />
      <Section>
        <Title.Default title={page.title} description={page.ingress} />
        <div className="content" style={{ paddingTop: 0 }}>
          <ControlLoop loop={loop} activeId={activeId} onItemClick={id => setActiveId(id)} />
        </div>

        <Section>
          <div className="primary-background">
            <div className="content">
              <div className="desktop-col-9">
                <Header
                  icon={currentLoopContent.icon}
                  title={currentLoopContent.name}
                  nextBtnText={page.nextBtnText}
                  prevBtnText={page.prevBtnText}
                  onPrevBtnClick={() => setActiveId(activeId - 1)}
                  onNextBtnClick={() => setActiveId(activeId + 1)}
                  hidePrevBtn={activeId <= 1}
                  hideNextBtn={activeId >= loop.length}
                />
                <Section>
                  <LoopContent loop={loop} activeId={activeId} />
                </Section>
              </div>
            </div>
            {activeId === 2 ? (
              <div className="desktop-col-12" style={{ paddingBottom: "8rem", marginTop: "4rem" }}>
                <IllustrationLink.Large
                  title={currentLoopContent.illustrationLink.title}
                  content={currentLoopContent.illustrationLink.ingress}
                  imageSource={currentLoopContent.illustrationLink.image}
                  imageAltText={currentLoopContent.illustrationLink.imageAlt}
                  portableContent
                  url=""
                />
              </div>
            ) : null}
          </div>
        </Section>
        <Section>
          <div className="content">
            <H className="h2">{page.relatedTitle}</H>
          </div>
          <Section>
            <IllustrationLink.Large
              title={page.relatedIllustrationLink.title}
              content={page.relatedIllustrationLink.ingress}
              imageSource={page.relatedIllustrationLink.image.asset.url}
              imageAltText={page.relatedIllustrationLink.image.alt}
              linkText={page.relatedIllustrationLink.buttonText}
              url={`/bransje/${industry.route}/roller-og-samarbeid`}
              background="yellow"
              portableContent
            />
          </Section>
        </Section>
        <BackLink to={`/bransje/${industry.route}`} />
      </Section>
    </>
  )
}

StyringssloyfenPage.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(StyringssloyfenPage)
