import React, { useState } from "react"
import { BackLink, Loading, VideoAndText, Button } from "../components"
import SEO from "../components/SEO"
import { IndustryType } from "../propTypes"
import withPageDependencies from "../hoc/withPageDependencies"
import useSanity from "../hooks/useSanity"
import { blockContentGroq } from "../groq"
import styles from "./_kunnskapsmodulen/style.module.scss"
import types from "../components/button/_types"

const Kunnskapsmodulen = ({ industry }) => {
  const [params] = useState({
    id: "knowledgePage",
    videoAndTextModuleId: "videoAndTextModule",
  })

  const { loading, error, data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
        ...,
        ingress[] ${blockContentGroq},
        knowledgeVideoPreviewTop {
          ...,
          asset->,
        },
        knowledgeVideoPreview1 {
          ...,
          asset->,
        },
        knowledgeVideoPreview2 {
          ...,
          asset->,
        },
        knowledgeVideoPreview3 {
          ...,
          asset->,
        },
        knowledgeSideTextTop[] ${blockContentGroq}
        ,
        knowledgeSideText1[] ${blockContentGroq}
        ,
        knowledgeSideText2[] ${blockContentGroq}
        ,
        knowledgeSideText3[] ${blockContentGroq}
        ,
        manus[] ${blockContentGroq}
        ,
        powerpoints[] ${blockContentGroq}
      } | order(_updatedAt desc) [0]`,
    params
  )

  let page = null
  let powerpointsUrl = ""
  let manusUrl = ""
  if (data !== null) {
    page = data
    powerpointsUrl = page !== null ? page.powerpoints[1].file?.asset?.url : ""
    manusUrl = page !== null ? page.manus[1].file?.asset?.url : ""
  }

  if (loading || error) return <Loading error={error} />

  const footerTag = window.document.getElementsByTagName("footer")
  if (footerTag.length > 0) {
    window.document.getElementsByTagName("footer")[0].style.backgroundColor = "#faf5e9"
  }

  return (
    <>
      <SEO title="Kunnskapsmodulen" />
      <a href="#knowledgeTop">
        <VideoAndText
          id="knowledgeTop"
          videoURL={page.knowledgeVideoURLTop}
          previewImage={page.knowledgeVideoPreviewTop.asset.url}
          sideText={page.knowledgeSideTextTop}
          className={styles.module}
        />
      </a>
      <div className={styles.sectionrektangle} />
      <div className={styles.section}>
        <div className={styles.buttons}>
          <a href="#modul1">
            <VideoAndText
              id="modul1"
              videoURL={page.knowledgeVideoURL1}
              previewImage={page.knowledgeVideoPreview1.asset.url}
              sideText={page.knowledgeSideText1}
              className={styles.module}
            />
          </a>
          <a href="#modul2">
            <VideoAndText
              id="modul2"
              videoURL={page.knowledgeVideoURL2}
              previewImage={page.knowledgeVideoPreview2.asset.url}
              sideText={page.knowledgeSideText2}
              className={styles.module}
            />
          </a>
          <a href="#modul3">
            <VideoAndText
              id="modul3"
              videoURL={page.knowledgeVideoURL3}
              previewImage={page.knowledgeVideoPreview3.asset.url}
              sideText={page.knowledgeSideText3}
              className={styles.module}
            />
          </a>
          <div className={styles.buttongroup}>
            <div className="content" style={{ justifyContent: "flex-end", padding: "0" }}>
              <Button.Secondary url={manusUrl} icon="manus" type={types.external}>
                {page.manus[1].title}
              </Button.Secondary>
              <Button.Secondary
                url={powerpointsUrl}
                icon="powerpoint"
                type={types.external}
                style={{ marginLeft: "2rem" }}
              >
                {page.powerpoints[1].title}
              </Button.Secondary>
            </div>
          </div>
        </div>
        <BackLink to={industry ? `/bransje/${industry.route}` : "/"} />
      </div>
    </>
  )
}

Kunnskapsmodulen.propTypes = {
  industry: IndustryType,
}

Kunnskapsmodulen.defaultProps = {
  industry: null,
}

export default withPageDependencies(Kunnskapsmodulen)
